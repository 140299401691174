import React from "react";
import { collection, query } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AdminDashboardLayout from "../../../layouts/admin_dashboard_layout/admin_dashboard_layout";
import { MAIN_THEME_COLOR } from "../../../providers/theme/colors/colors";
import { Edit, People } from "@mui/icons-material";

interface Props {}

function Surveys(props: Props) {
  const firestore = useFirestore();
  const {} = props;
  const navigate = useNavigate();

  const collectionDataForYuvadabazo = collection(firestore, "survey");
  const chapterCollectionQueryForYuvaDabazo = query(
    collectionDataForYuvadabazo
  );

  const { data: responseList } = useFirestoreCollectionData(
    chapterCollectionQueryForYuvaDabazo
  );

  return (
    <AdminDashboardLayout>
      <Box display="flex" flexDirection={"column"}>
        <Box mx={2} my={4} height={500}>
          <Grid container spacing={2}>
            {responseList?.map((survey) => {
              return (
                <Grid
                  item
                  sm={12}
                  lg={4}
                  xl={4}
                  md={6}
                  key={survey.id}
                  width={"100%"}
                >
                  <Card
                    sx={{
                      border: `1px solid ${MAIN_THEME_COLOR}`,
                      p: 2,
                    }}
                  >
                    <Grid container spacing={1} alignItems={"center"}>
                      <Grid item sm={12} md={12} lg={6}>
                        <Box>
                          <Typography variant="body1">
                            {survey?.surveyQuestionaire?.title}
                          </Typography>
                          <Typography mt={1} variant="body2" fontSize={12}>
                            Total Questions: {survey?.totalQuestions}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={12} md={12} lg={6} xs={12}>
                        <Box display={"flex"} justifyContent={"flex-end"}>
                          <IconButton
                            sx={{
                              bgcolor: "primary.main",
                              width: "fit-content",
                            }}
                            onClick={() => {
                              navigate(
                                `/admin/surveys/createSurvey/${survey?.NO_ID_FIELD}`
                              );
                            }}
                          >
                            <People style={{ height: 20, width: 20 }} />
                          </IconButton>
                          <IconButton
                            sx={{
                              ml: 1,
                              bgcolor: "primary.main",
                              width: "fit-content",
                            }}
                            onClick={() => {
                              navigate(
                                `/admin/surveys/editSurvey/${survey?.NO_ID_FIELD}`
                              );
                            }}
                          >
                            <Edit style={{ height: 20, width: 20 }} />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box display={"flex"}>
          <Grid container display={"flex"} justifyContent={"flex-end"}>
            <Grid item sm={4} md={4} lg={4}>
              <Box display={"flex"} mr={2} mb={3}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => navigate("/admin/surveys/createSurvey")}
                >
                  Create survey
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </AdminDashboardLayout>
  );
}

export default Surveys;
