import { Box, Card } from "@mui/material";
import React from "react";
import MainLayout from "../../layouts/main_layout/main_layout";
import DataGrid from "./components/data_grid";

interface Props {}

function RegistrationList(props: Props) {
  const {} = props;

  return (
    <MainLayout>
      <Box mt={6} mb={4}>
        <Card
          sx={{
            overflow: "scroll",
            minHeight: 300,
            maxHeight: 500,
          }}
        >
          <DataGrid />
        </Card>
      </Box>
    </MainLayout>
  );
}

export default RegistrationList;
