import {
  AnalyticsOutlined,
  Logout,
  Newspaper,
  People,
  PeopleOutlineOutlined,
  Reviews,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  Drawer,
} from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "reactfire";

function AdminDrawer() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const [open, setOpen] = React.useState(false);

  let dashboardItems = [
    {
      id: 1,
      name: "Dashboard",
      path: "/admin/home",
      activeIcon: <AnalyticsOutlined sx={{ pl: 1, color: "#0085DB" }} />,
      inActiveicon: <AnalyticsOutlined sx={{ pl: 1, color: "#565564" }} />,
    },
    {
      id: 2,
      name: "Users",
      path: "/admin/users",
      activeIcon: <People sx={{ pl: 1, color: "#0085DB" }} />,
      inActiveicon: <PeopleOutlineOutlined sx={{ pl: 1, color: "#565564" }} />,
    },
    {
      id: 3,
      name: "Upload News",
      path: "/admin/uploadNews",
      activeIcon: <Newspaper sx={{ pl: 1, color: "#0085DB" }} />,
      inActiveicon: <Newspaper sx={{ pl: 1, color: "#565564" }} />,
    },
    {
      id: 4,
      name: "Survey",
      path: "/admin/surveys",
      activeIcon: <Reviews sx={{ pl: 1, color: "#ffffff" }} />,
      inActiveicon: <Reviews sx={{ pl: 1, color: "#565564" }} />,
    },
    {
      id: 4,
      name: "Logout",
      path: "",
      activeIcon: <Logout sx={{ pl: 1, color: "#ffffff" }} />,
      inActiveicon: <Logout sx={{ pl: 1, color: "#565564" }} />,
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      {dashboardItems?.map((item) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"row"}
            onClick={() => {
              if (item?.name === "Logout") {
                auth?.signOut();
                navigate("/admin/login");
              } else {
                navigate(item?.path);
              }
            }}
            sx={{
              my: 1,
              py: 2,
              pl: 2,
              mr: 2,
              backgroundColor:
                pathname === item?.path ? "#0085DB1F" : "transparent",
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              position: "relative",
              overflow: "hidden",
              ":before": {
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: "100%",
                backgroundColor: "#0085DB1F",
                transition: "left 0.3s ease-in-out",
                zIndex: 0,
              },
              ":hover:before": {
                left: 0,
              },
              ":hover": {
                border: "0.1px solid #808080",
              },
              justifyContent: "start",
              zIndex: 1,
            }}
          >
            {pathname === item?.path ? item?.activeIcon : item?.inActiveicon}
            <Typography
              textAlign={"start"}
              variant="subtitle2"
              color={pathname === item?.path ? "#0085DB" : "#565564"}
              ml={2}
            >
              {item?.name}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}

export default AdminDrawer;
