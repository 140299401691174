import React from "react";
import MainLayout from "../../layouts/main_layout/main_layout";
import { collection, query } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { MAIN_THEME_COLOR } from "../../providers/theme/colors/colors";
import { useNavigate } from "react-router-dom";

interface Props {}

function Surveys(props: Props) {
  const firestore = useFirestore();
  const {} = props;
  const navigate = useNavigate();

  const collectionDataForYuvadabazo = collection(firestore, "survey");
  const chapterCollectionQueryForYuvaDabazo = query(
    collectionDataForYuvadabazo
  );

  const { data: responseList } = useFirestoreCollectionData(
    chapterCollectionQueryForYuvaDabazo
  );

  return (
    <MainLayout>
      <Box my={2}>
        <Card>
          <Box mx={2} my={4}>
            <Grid container spacing={2}>
              {responseList?.map((survey) => {
                return (
                  <Grid
                    item
                    sm={12}
                    lg={4}
                    xl={4}
                    md={6}
                    key={survey.id}
                    width={"100%"}
                  >
                    <Card
                      sx={{
                        border: `1px solid ${MAIN_THEME_COLOR}`,
                        p: 2,
                      }}
                    >
                      <Grid container spacing={1} alignItems={"center"}>
                        <Grid item sm={12} md={12} lg={6}>
                          <Box>
                            <Typography variant="body1">
                              {survey?.surveyQuestionaire?.title}
                            </Typography>
                            <Typography mt={1} variant="body2" fontSize={12}>
                              Total Questions: {survey?.totalQuestions}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          md={12}
                          lg={6}
                          xs={12}
                          justifyItems={{ lg: "flex-end" }}
                        >
                          <Box display={"flex"}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                navigate(
                                  `/surveyResponse/${survey?.NO_ID_FIELD}`
                                );
                              }}
                              sx={{ width: "fit-content" }}
                            >
                              Conduct Survey
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* </Box> */}
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Card>
      </Box>
    </MainLayout>
  );
}

export default Surveys;
