import { Box, Grid, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { SECONDARY_THEME_COLOR } from "../../../providers/theme/colors/colors";
import { ArrowRightAlt } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-multi-carousel/lib/styles.css";
import theme from "../../../providers/theme";

interface Props {}

function YuvaDabazoSection(props: Props) {
  const {} = props;
  const navigate = useNavigate();
  const [tabSlideIndexes, setTabSlideIndexes] = useState([0, 0, 0]);

  const carouselsRef = useRef<Carousel>(null);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Grid container spacing={4} mt={0}>
      <Grid item md={6} lg={6} sm={12}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          height={"100%"}
          justifyContent={"center"}
        >
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            Empowering Leaders: Spirit Quest & NextGen Digital
          </Typography>
          <Typography variant="body1" mb={2}>
            We are excited to introduce two exclusive training programs designed
            to equip ICYM leaders of the Udupi Diocese with essential skills and
            knowledge. These sessions will provide valuable insights from expert
            resource persons and help participants grow in their respective
            roles.
          </Typography>

          <Box component="ul" pl={2} mb={2}>
            <Typography component="li" variant="body1" mb={1}>
              <strong>Spirit Quest</strong>: A specialized training program for
              all ICYM Unit and Deanery YOUCAT Coordinators of the Udupi
              Diocese.
              <Box component="ul" pl={2} mt={1}>
                <Typography component="li" variant="body2">
                  📌 Resource Person: Fr. Alwyn D’Souza, Director, Family
                  Commission, Diocese of Mangalore
                </Typography>
                <Typography component="li" variant="body2">
                  📅 Date: 16-Feb-2025
                </Typography>
                <Typography component="li" variant="body2">
                  📍 Venue: Anugraha Pastoral Cente
                </Typography>
                <Typography component="li" variant="body2">
                  🕚 Schedule: 11:00 AM – Registration, 11:30 AM – Training
                  Session, Registration Fee: ₹200
                </Typography>
              </Box>
            </Typography>
          </Box>
          <Box component="ul" pl={2} mb={2}>
            <Typography component="li" variant="body1" mb={1}>
              <strong>NextGen Digital</strong>: A comprehensive training program
              for all ICYM Unit and Deanery P.R.O.s of the Udupi Diocese.
            </Typography>
            <Box component="ul" pl={2} mt={1}>
              <Typography component="li" variant="body2">
                📌 Resource Person: Fr. Anil Fernandes, Director, Canara
                Communication Center, Diocese of Mangalore
              </Typography>
              <Typography component="li" variant="body2">
                📅 Date: 16-Feb-2025
              </Typography>
              <Typography component="li" variant="body2">
                📍 Venue: Anugraha Pastoral Cente
              </Typography>
              <Typography component="li" variant="body2">
                🕚 8:00 AM – Registration, 9:00 AM – Holy Eucharist, 10:00 AM –
                Training Session, Registration Fee: ₹200
              </Typography>
            </Box>
          </Box>

          <Box
            display={"flex"}
            mt={1}
            borderBottom={`3px solid ${SECONDARY_THEME_COLOR}`}
            ml={2}
            width={"fit-content"}
            onClick={() => {
              navigate("/proYoucatTraining");
              window.scrollTo(0, 0);
            }}
            sx={{ cursor: "pointer" }}
          >
            <Typography variant="body2" fontWeight={"600"}>
              Register Now
            </Typography>
            <ArrowRightAlt />
          </Box>
        </Box>
      </Grid>
      <Grid display={"flex"} item md={6} lg={6} sm={12} xs={12}>
        <Box width="100%" maxWidth="100%" overflow="hidden">
          <Carousel
            autoPlay={true}
            dynamicHeight={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={true}
            showArrows={false}
            interval={2000}
            renderItem={(children) => {
              return <Box mb={6}>{children}</Box>;
            }}
            renderIndicator={(onClick, isSelected) => {
              return (
                <Box
                  display="inline-block"
                  mx={0.2}
                  mt={10}
                  sx={{
                    borderRadius: 8,
                    width: isSelected ? 24 : 8,
                    transition: "all 1s",
                    height: 8,
                    backgroundColor: isSelected
                      ? theme.palette.primary.main
                      : "white",
                    border: `1px solid ${theme.palette.primary.main}`,
                    mr: 2,
                  }}
                />
              );
            }}
          >
            {["/images/nextgen.jpeg", "/images/spiritquest.jpeg"].map(
              (image) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        maxWidth: 350, // Ensures it does not overflow
                        borderRadius: 20,
                        objectFit: "fill",
                      }}
                      src={image}
                      alt=""
                    />
                  </Box>
                );
              }
            )}
          </Carousel>
        </Box>
      </Grid>
    </Grid>
  );
}

export default YuvaDabazoSection;
