import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import AdminDashboardLayout from "../../../../layouts/admin_dashboard_layout/admin_dashboard_layout";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { useNavigate, useParams } from "react-router-dom";
import { collection, query } from "firebase/firestore";
import { isMobile } from "react-device-detect";
import { MAIN_THEME_COLOR } from "../../../../providers/theme/colors/colors";
import { ArrowForwardIos } from "@mui/icons-material";

interface Props {}

function SurveyRespodents(props: Props) {
  const {} = props;

  const firestore = useFirestore();
  const { id } = useParams();
  const navigate = useNavigate();

  const collectionDataForsurvey = collection(
    firestore,
    "survey",
    id!,
    "surveyResponses"
  );
  const chapterCollectionQueryForSurvey = query(collectionDataForsurvey);

  const { data: respondenceList } = useFirestoreCollectionData(
    chapterCollectionQueryForSurvey
  );

  let labels = [
    {
      id: 1,
      name: "Sl. No",
    },
    {
      id: 2,
      name: "Respondent Name",
    },
    {
      id: 3,
      name: "Parish",
    },
    {
      id: 4,
      name: "Deanery",
    },
    {
      id: 5,
      name: "Age",
    },
  ];

  console.log("Survey List:", respondenceList);

  return (
    <AdminDashboardLayout>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        px={4}
        py={2}
      >
        <Box display={"flex"} flexDirection={"row"} mt={2}>
          {labels?.map((item, index) => {
            if (isMobile && index < 2) {
              return (
                <Box display={"flex"} flex={1}>
                  <Typography color={MAIN_THEME_COLOR} fontWeight={600}>
                    {item?.name}
                  </Typography>
                </Box>
              );
            }
            if (!isMobile) {
              return (
                <Box display={"flex"} flex={1}>
                  <Typography color={MAIN_THEME_COLOR} fontWeight={600}>
                    {item?.name}
                  </Typography>
                </Box>
              );
            }
          })}
        </Box>
        <Box display={"flex"} flexDirection={"column"}>
          {respondenceList?.length === 0 ? (
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              mt={8}
            >
              <Typography fontWeight={600}>No Data Available</Typography>
            </Box>
          ) : (
            respondenceList?.map?.((item, index) => {
              return (
                <Box
                  onClick={() =>
                    navigate(
                      `/admin/surveys/respodentDetails/${item?.NO_ID_FIELD}`
                    )
                  }
                  display={"flex"}
                  flexDirection={"row"}
                  py={4}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography display={"flex"} flex={1}>
                    {index + 1}
                  </Typography>
                  <Box display={"flex"} flex={1}>
                    <Typography>{item?.answers?.fullName}</Typography>
                  </Box>
                  {!isMobile && (
                    <>
                      <Typography display={"flex"} flex={1}>
                        {item?.answers?.parishName}
                      </Typography>
                      <Box display={"flex"} flex={1}>
                        <Typography>{item?.answers?.deaneryName}</Typography>
                      </Box>
                      <Box display={"flex"} flex={1}>
                        <Typography>{item?.answers?.age}</Typography>
                        <Box
                          display={"flex"}
                          justifyContent={"flex-end"}
                          width={"100%"}
                        >
                          <ArrowForwardIos />
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              );
            })
          )}
        </Box>
      </Box>
    </AdminDashboardLayout>
  );
}

export default SurveyRespodents;
