import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { collection, query } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIos, Download } from "@mui/icons-material";
import * as XLSX from "xlsx";
import { MAIN_THEME_COLOR } from "../../../providers/theme/colors/colors";

interface Props {}

function DataGrid(props: Props) {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalproCount, setTotalproCount] = useState<number>(0);
  const [totalyoucatCount, setTotalyoucatCount] = useState<number>(0);
  const {} = props;
  const firestore = useFirestore();
  const navigate = useNavigate();

  let labels = [
    {
      id: 1,
      name: "Sl. No",
    },
    {
      id: 2,
      name: "Representative From",
    },
    {
      id: 3,
      name: "Parish",
    },
    {
      id: 4,
      name: "Deanery",
    },
    {
      id: 5,
      name: "Training Program",
    },
    {
      id: 6,
      name: "Reciept",
    },
  ];

  const collectionDataForTraining = collection(firestore, "proYoucatTraining");
  const chapterCollectionQueryForTraining = query(collectionDataForTraining);

  const { data: registrationList } = useFirestoreCollectionData(
    chapterCollectionQueryForTraining
  );

  const handleExport = () => {
    let kcount = 0;
    const kundapurDeaneryData = registrationList
      ?.map((item, index) => {
        if (+item?.deaneryName?.id === 1) {
          kcount = kcount + 1;
          return {
            "Sl. No": kcount,
            "full Name": item?.fullName,
            "Deanery Name": item?.deaneryName?.name,
            "Parish Name": item?.parishName?.value,
            "Contact Number": item?.contactNumber,
            "Email Id": item?.email,
            "Updated At": item?.updatedAt,
            "Training Program": item?.trainingProgram?.name,
            "Representative From": item?.RepresentativeFrom?.name,
            "Reciept photo": item?.reciept,
          };
        }
        return null;
      })
      .filter(Boolean);
    let kacount = 0;
    const karkalaDeaneryData = registrationList
      ?.map((item, index) => {
        if (+item?.deaneryName?.id === 3) {
          kacount = kacount + 1;
          return {
            "Sl. No": kacount,
            "full Name": item?.fullName,
            "Deanery Name": item?.deaneryName?.name,
            "Parish Name": item?.parishName?.value,
            "Contact Number": item?.contactNumber,
            "Email Id": item?.email,
            "Updated At": item?.updatedAt,
            "Training Program": item?.trainingProgram?.name,
            "Representative From": item?.RepresentativeFrom?.name,
            "Reciept photo": item?.reciept,
          };
        }
        return null;
      })
      .filter(Boolean);
    let scount = 0;
    const shirvaDeaneryData = registrationList

      ?.map((item, index) => {
        if (+item?.deaneryName?.id === 5) {
          scount = scount + 1;
          return {
            "Sl. No": scount,
            "full Name": item?.fullName,
            "Deanery Name": item?.deaneryName?.name,
            "Parish Name": item?.parishName?.value,
            "Contact Number": item?.contactNumber,
            "Email Id": item?.email,
            "Updated At": item?.updatedAt,
            "Training Program": item?.trainingProgram?.name,
            "Representative From": item?.RepresentativeFrom?.name,
            "Reciept photo": item?.reciept,
          };
        }
        return null;
      })
      .filter(Boolean);

    let kkcount = 0;
    const kallianpurDeaneryData = registrationList
      ?.map((item, index) => {
        if (+item?.deaneryName?.id === 4) {
          kkcount = kkcount + 1;
          return {
            "Sl. No": kkcount,
            "full Name": item?.fullName,
            "Deanery Name": item?.deaneryName?.name,
            "Parish Name": item?.parishName?.value,
            "Contact Number": item?.contactNumber,
            "Email Id": item?.email,
            "Updated At": item?.updatedAt,
            "Training Program": item?.trainingProgram?.name,
            "Representative From": item?.RepresentativeFrom?.name,
            "Reciept photo": item?.reciept,
          };
        }
        return null;
      })
      .filter(Boolean);

    let ucount = 0;

    const udupiDeaneryData = registrationList
      ?.map((item, index) => {
        if (+item?.deaneryName?.id === 2) {
          ucount = ucount + 1;
          return {
            "Sl. No": ucount,
            "full Name": item?.fullName,
            "Deanery Name": item?.deaneryName?.name,
            "Parish Name": item?.parishName?.value,
            "Contact Number": item?.contactNumber,
            "Email Id": item?.email,
            "Updated At": item?.updatedAt,
            "Training Program": item?.trainingProgram?.name,
            "Representative From": item?.RepresentativeFrom?.name,
            "Reciept photo": item?.reciept,
          };
        }

        return null;
      })
      .filter(Boolean);

    const ws2 = XLSX.utils.json_to_sheet(kundapurDeaneryData);
    const ws1 = XLSX.utils.json_to_sheet(udupiDeaneryData);
    const ws3 = XLSX.utils.json_to_sheet(shirvaDeaneryData);
    const ws4 = XLSX.utils.json_to_sheet(karkalaDeaneryData);
    const ws5 = XLSX.utils.json_to_sheet(kallianpurDeaneryData);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws2, "Kundapura");
    XLSX.utils.book_append_sheet(wb, ws1, "Udupi");
    XLSX.utils.book_append_sheet(wb, ws3, "Shirva");
    XLSX.utils.book_append_sheet(wb, ws4, "Karkala");
    XLSX.utils.book_append_sheet(wb, ws5, "Kallianpur");

    // Export file
    XLSX.writeFile(wb, "youcatProTraining.xlsx");
  };

  useEffect(() => {
    let count = registrationList?.length;
    let proc = 0;
    let youcatC = 0;
    registrationList?.map((item) => {
      if (item?.trainingProgram.id === 2) {
        youcatC = youcatC + 1;
      } else {
        proc = proc + 1;
      }
    });
    setTotalCount(count);
    setTotalproCount(proc);
    setTotalyoucatCount(youcatC);
  }, [registrationList]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      px={4}
      py={2}
    >
      <Box display={"flex"} flexDirection={"row"}>
        <Typography color="#000000" variant="body2">
          Download as :
        </Typography>

        <Box onClick={handleExport} ml={1} sx={{ cursor: "pointer" }}>
          <img
            src="/images/icons/excel.jpeg"
            style={{ height: 20, width: 20 }}
          />
        </Box>
      </Box>
      <Grid container>
        <Grid lg={4} md={4} sm={12} xs={12}>
          <Box display={"flex"} flexDirection={"row"}>
            <Typography color="#000000" variant="body2">
              Total Attendees :
            </Typography>
            <Box ml={1}>
              <Typography color="#000000" variant="body2" fontWeight={600}>
                {totalCount}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid lg={4} md={4} sm={12} xs={12}>
          <Box display={"flex"} flexDirection={"row"}>
            <Typography color="#000000" variant="body2">
              Total Pro Count :
            </Typography>
            <Typography color="#000000" variant="body2" fontWeight={600} ml={1}>
              {totalproCount}
            </Typography>
          </Box>
        </Grid>
        <Grid lg={4} md={4} sm={12} xs={12}>
          <Box display={"flex"} flexDirection={"row"}>
            <Typography color="#000000" variant="body2">
              Total Youcat Count :
            </Typography>
            <Typography color="#000000" variant="body2" fontWeight={600} ml={1}>
              {totalyoucatCount}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box display={"flex"} flexDirection={"row"} mt={2}>
        {labels?.map((item, index) => {
          if (isMobile && index < 2) {
            return (
              <Box display={"flex"} flex={1}>
                <Typography color={MAIN_THEME_COLOR} fontWeight={600}>
                  {item?.name}
                </Typography>
              </Box>
            );
          }
          if (!isMobile) {
            return (
              <Box display={"flex"} flex={1}>
                <Typography color={MAIN_THEME_COLOR} fontWeight={600}>
                  {item?.name}
                </Typography>
              </Box>
            );
          }
        })}
      </Box>
      <Box display={"flex"} flexDirection={"column"}>
        {registrationList?.length === 0 ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={8}
          >
            <Typography fontWeight={600}>No Data Available</Typography>
          </Box>
        ) : (
          registrationList?.map?.((item, index) => {
            return (
              <Box
                display={"flex"}
                flexDirection={"row"}
                py={4}
                sx={{ cursor: "pointer" }}
              >
                <Typography display={"flex"} flex={1}>
                  {index + 1}
                </Typography>
                <Box display={"flex"} flex={1}>
                  <Typography>{item?.RepresentativeFrom?.name}</Typography>
                </Box>
                {!isMobile && (
                  <>
                    <Typography display={"flex"} flex={1}>
                      {item?.parishName?.value}
                    </Typography>
                    <Box display={"flex"} flex={1}>
                      <Typography>{item?.deaneryName?.name}</Typography>
                    </Box>
                    <Box display={"flex"} flex={1}>
                      <Typography>{item?.trainingProgram?.name}</Typography>
                    </Box>
                    <Box display={"flex"} flex={1}>
                      <a
                        href={item?.reciept}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                        download
                      >
                        <Download />
                      </a>
                    </Box>
                  </>
                )}
              </Box>
            );
          })
        )}
      </Box>
    </Box>
  );
}

export default DataGrid;
