import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/main_layout/main_layout";
import { Survey } from "survey-react-ui";
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDoc,
  query,
} from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { Model } from "survey-core";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { getSurveyorDetails } from "../../../services/survey_services/survey_services";
import "./style.css";

interface Props {}

function SurveyResponse(props: Props) {
  const { id } = useParams();
  const firestore = useFirestore();
  const {} = props;
  const [surveyRecord, setSurveyRecord] = useState<DocumentData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [surveyModel, setSurveyModel] = useState<Model | null>(null);
  const [surveyorDetails, setSurveyorDetails] = useState<any>(null);

  const getSpecificRecord = async () => {
    setIsLoading(true);
    try {
      const docRef = doc(firestore, "survey", id!);

      const docSnap = await getDoc(docRef);

      setIsLoading(false);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setSurveyRecord(docSnap.data());
        const surveyJson = surveyRecord?.surveyQuestionaire;
        let surveyModel;
        if (surveyJson) {
          surveyModel = new Model(surveyJson);
          setSurveyModel(surveyModel);
        }
        return docSnap.data();
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      setIsLoading(false);
      return null;
    }
  };

  function getSurveyorDetailsById(surveyId: string) {
    getSurveyorDetails(surveyId)
      .then((res) => {
        setSurveyorDetails(res?.data);
      })
      .catch((e: any) => {
        setSurveyorDetails(null);
      });
  }

  useEffect(() => {
    getSpecificRecord();
  }, []);

  useEffect(() => {
    surveyModel?.setValue("deaneryName", surveyorDetails?.deaneryName);
    surveyModel?.setValue("parishName", surveyorDetails?.parishName);
  }, [surveyorDetails]);

  useEffect(() => {
    surveyModel?.onValueChanged?.add?.((sender, options) => {
      if (options.name === "surveyCode") {
        if (options.value.length === 12) {
          getSurveyorDetailsById(options?.value);
        } else {
          surveyModel?.setValue("deaneryName", "");
          surveyModel?.setValue("parishName", "");
        }
      }
    });
  }, [surveyModel?.onValueChanged]);

  useEffect(() => {
    let surveyResponseInStrcturedWay: any = {};
    const surveyJson = surveyRecord?.surveyQuestionaire;
    if (surveyJson) {
      const model = new Model(surveyJson);
      model.onComplete.add((sender) => {
        const surveyData = sender.data;
        const questions = sender.getAllQuestions();
        const detailedResponses = questions.map((question) => {
          if (question.getType() === "dropdown") {
            surveyResponseInStrcturedWay[`${question.name}Id`] =
              surveyData[question.displayValue] || surveyData[question.name];
            surveyResponseInStrcturedWay[`${question.name}`] =
              question.displayValue || surveyData[question.name];
          } else {
            surveyResponseInStrcturedWay[question.name] =
              surveyData[question.displayValue] || surveyData[question.name];
          }
          return {
            id: question?.id,
            name: question.name,
            title: question.title,
            value: surveyData[question.name],
            displayValue: question.displayValue || surveyData[question.name],
            isNode: Array.isArray(surveyData[question.name]),
            data: Array.isArray(surveyData[question.name])
              ? surveyData[question.name].map((item: any, index: number) => ({
                  name: index,
                  title: "Choice",
                  value: item,
                  displayValue: item,
                  isNode: false,
                }))
              : [],
          };
        });
        let date = new Date();
        const finalResponse = {
          checksum: Date.now(), // Generate a unique checksum
          answers: surveyResponseInStrcturedWay,
        };
        const surveyResponsesCollection = collection(
          firestore,
          `survey/${id}/surveyResponses`
        );
        addDoc(surveyResponsesCollection, finalResponse);
      });
      setSurveyModel(model);
    }
  }, [surveyRecord]);

  return (
    <MainLayout>
      <Box
        display="flex"
        my={3}
        width="100%"
        maxWidth="900px"
        mx="auto"
        overflow="hidden"
        flexDirection="column"
      >
        {surveyModel && <Survey model={surveyModel} />}
      </Box>
    </MainLayout>
  );
}

export default SurveyResponse;
