import { Login } from "@mui/icons-material";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "reactfire";

interface PrivateRouteProps {
  element: JSX.Element;
  module?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, module }) => {
  const auth = useAuth();
  return auth.currentUser ? element : <Navigate to="/admin/login" />;
};

export default PrivateRoute;
