import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/main_layout/main_layout";
import ComingSoon from "../components/coming_soon/coming_soon";
import { doc, DocumentData, getDoc } from "firebase/firestore";
import { useFirestore } from "reactfire";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./styles.css";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function News() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [recordDetails, setRecordDetails] = useState<DocumentData | null>(null);
  const firestore = useFirestore();

  const getSpecificRecord = async () => {
    setIsLoading(true);
    try {
      const docRef = doc(firestore, "news", id!);
      const docSnap = await getDoc(docRef);
      setIsLoading(false);
      if (docSnap.exists()) {
        setRecordDetails(docSnap.data());
        return docSnap.data();
      } else {
        return null;
      }
    } catch (error) {
      setIsLoading(false);
      return null;
    }
  };

  useEffect(() => {
    getSpecificRecord();
  }, []);

  return (
    <div>
      <Helmet>
        <meta
          property="og:description"
          content={recordDetails?.subtitle || "Default Description"}
        />
        <meta name="description" content={recordDetails?.subtitle} />
        <meta
          property="og:image"
          content={recordDetails?.gallery?.[16] || "default-image-url.jpg"}
        />
        <meta
          name="image"
          content={recordDetails?.gallery?.[16] || "default-image-url.jpg"}
        />
        <meta
          property="og:url"
          content={`https://youthcommissionudupi.in/news/${id}`}
        />
        <meta
          name="url"
          content={`https://youthcommissionudupi.in/news/${id}`}
        />
        <link
          rel="canonical"
          href={`https://youthcommissionudupi.in/news/${id}`}
        />
        <title>{recordDetails?.title || "Default Title"}</title>
        <meta
          property="og:title"
          content={recordDetails?.title || "Default Title"}
        />
      </Helmet>

      <MainLayout>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            height="100vh"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container justifyContent={"center"} spacing={3}>
            <Grid
              display={"flex"}
              justifyContent={"flex-end"}
              flexDirection={"column"}
              item
              md={7}
              lg={7}
              sm={12}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                mt={4}
                width={"100%"}
              >
                <Typography
                  display={"flex"}
                  sx={{
                    fontSize: {
                      xs: 20,
                      md: 26,
                    },
                  }}
                  textAlign="center"
                >
                  {recordDetails?.title}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 16,
                      md: 22,
                    },
                  }}
                  fontWeight={600}
                >
                  {recordDetails?.subtitle}
                </Typography>
              </Box>
              <Box mt={4}>
                <Box display={"flex"} mb={2}>
                  <Box
                    width={"fit-content"}
                    border="1px solid black"
                    borderRadius={10}
                    mr={3}
                    height={"fit-content"}
                  >
                    <Avatar
                      alt="icym logo"
                      src="/images/icons/icym_icon.jpg"
                      sx={{ height: 50, width: 50 }}
                    />
                  </Box>
                  <Box>
                    <Typography fontWeight={"600"}>
                      {recordDetails?.postedBy}
                    </Typography>
                    <Typography variant="body2">
                      {"Last Updated : "}{" "}
                      {new Date(
                        recordDetails?.createdAt.seconds * 1000 +
                          recordDetails?.createdAt?.nanoseconds / 1000000
                      ).toLocaleString()}
                    </Typography>
                  </Box>
                </Box>

                <Card>
                  <img
                    src={
                      recordDetails?.thumbnail
                        ? recordDetails?.thumbnail
                        : recordDetails?.gallery[0]
                    }
                    alt=""
                    style={{ objectFit: "contain", width: "100%" }}
                  />
                </Card>
              </Box>
              <Box mt={2}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: recordDetails?.description,
                  }}
                />
              </Box>
              <Box my={3}>
                <ImageGallery
                  showThumbnails={false}
                  items={
                    recordDetails?.gallery
                      ? recordDetails?.gallery?.map?.((item: string) => {
                          return {
                            original: item,
                            thumbnail: item,
                          };
                        })
                      : []
                  }
                  showFullscreenButton={
                    false
                  } /* Ensure fullscreen button is enabled */
                />
              </Box>
            </Grid>
            {/* <Grid item md={4} lg={4} sm={12}>
          <Box mt={4}>
            <Typography variant="h6">{"Related Stories"}</Typography>
            <Box borderBottom={"3px solid #77C3EC"} borderRadius={10} />
          </Box>
          <Box>
            {relatedNews?.map((item) => {
              return (
                <Box display={"flex"} mt={2}>
                  <Card sx={{ borderBottom: "3px solid #77C3EC" }}>
                    <img
                      src={item?.images}
                      alt=""
                      height={80}
                      width={300}
                      style={{ objectFit: "cover" }}
                    />
                  </Card>
                  <Box ml={1}>
                    <Typography fontWeight={"600"}>
                      ಬೆಂಕಿ ಹಚ್ಚುತ್ತೇನೆಂದು ಅನ್ಯಕೋಮಿನ ಯುವಕನಿಂದ ಬೆದರಿಕೆ;
                      ಮೈಸೂರು-ಅಯೋಧ್ಯೆ ರೈಲು 2 ಗಂಟೆ ಸ್ಥಗಿತ!
                    </Typography>
                    <Typography variant="subtitle2">
                      By: Icym Udupi Deanery
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Grid> */}
          </Grid>
        )}
      </MainLayout>
    </div>
  );
}

// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.48%20PM.jpeg?alt=media&token=8a51a913-6789-4cea-a1af-2769576b75f0
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.49%20PM%20(1).jpeg?alt=media&token=007c6cb3-5e2a-491a-8c55-4ece8a1e09ae
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.49%20PM%20(2).jpeg?alt=media&token=5ea698eb-fd10-47ea-94c0-3ad6b79e5b20
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.49%20PM%20(3).jpeg?alt=media&token=d1538aec-0f37-43ff-8c1c-5a8da1f139de
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.49%20PM.jpeg?alt=media&token=e405a9d1-e1e6-428a-bd6d-1fc6802252d5
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.50%20PM%20(1).jpeg?alt=media&token=25547f1c-a9bd-45d5-baf8-276d0cde3ae2
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.50%20PM%20(2).jpeg?alt=media&token=7c241f4a-2510-463e-9f95-c47d1042e149
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.50%20PM%20(3).jpeg?alt=media&token=52fa12e7-616a-4aa5-b2d3-0397f400da00
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.50%20PM.jpeg?alt=media&token=2422136d-c46e-41e3-a3c3-cd0646fa15e8
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.51%20PM%20(1).jpeg?alt=media&token=e6a680eb-98f0-42c3-b874-25387fc67dfd
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.51%20PM%20(2).jpeg?alt=media&token=82f10c9f-ed84-42bc-9449-a15e81838bb4
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.51%20PM%20(3).jpeg?alt=media&token=08b862a0-bf9e-475b-ae20-4e6d825a7ecd
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.51%20PM.jpeg?alt=media&token=9ed1f445-23f5-430c-a628-8cbb05bc76a6
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.52%20PM%20(1).jpeg?alt=media&token=0a6ba752-fe07-413b-97cf-2648a7ee5c63
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.52%20PM%20(2).jpeg?alt=media&token=0ce417a5-5692-4887-90d8-91ca1be69195
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.52%20PM.jpeg?alt=media&token=1432dec1-2ecc-42e0-b2cc-eb71b22da5ea
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.53%20PM%20(1).jpeg?alt=media&token=f1feb823-ee9d-4015-9a81-1fc48bb773cb
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.53%20PM%20(2).jpeg?alt=media&token=026c6222-d61f-4ad2-90b8-0caa57a28cbb
// https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/news%2FWhatsApp%20Image%202024-10-01%20at%206.58.53%20PM%20(3).jpeg?alt=media&token=61b557df-50f9-479c-9308-0c70f2e6258b
