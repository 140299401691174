 
import axios, { AxiosInstance } from "axios";
import { baseURL } from "./config";

const HttpClient: AxiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});
export default HttpClient;