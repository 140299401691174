import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import AdminDashboardLayout from "../../../layouts/admin_dashboard_layout/admin_dashboard_layout";
import { ArrowBack, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ref } from "firebase/storage";
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { firestore, storage } from "../../..";
import {
  Deanery,
  deaneryList,
  Parish,
  parishListMap,
} from "../../yuva_dabazo/yuva_dabazo";
import { useFeedback } from "../../../providers/feedback/feedback";

interface Props {}

function UploadNews() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [postedBy, setPostedBy] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [parishList, setParishList] = useState<Parish[]>([]);
  const { showSnackbar } = useFeedback();
  const [_selectedDeanery, _setSelectedDeanery] = useState<{
    id: Number;
    name: string;
  } | null>(null);
  const [_selectedParish, _setSelectedParish] = useState<{
    id: Number;
    value: string;
  } | null>(null);
  const [galleryImages, setGalleryImages] = useState<string[]>([]);

  const handleDeaneryChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: Deanery | null
  ) => {
    if (value) {
      _setSelectedDeanery({ id: value.id, name: value.name });
      setParishList(parishListMap[value.name] || []);
      _setSelectedParish(null);
    } else {
      _setSelectedDeanery(null);
      setParishList([]);
    }
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      let images: string[] = [];

      const promises = selectedImages?.map(async (image) => {
        const storageRef = ref(storage, `news/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);
        await uploadTask;
        const downloadURL = await getDownloadURL(storageRef);
        images.push(downloadURL);
      });
      const storageRef = ref(storage, `news/${selectedThumbnail?.name}`);
      const uploadTaskForTuhumbail = uploadBytesResumable(
        storageRef,
        selectedThumbnail!
      );
      await uploadTaskForTuhumbail;
      const downloadURL = await getDownloadURL(storageRef);
      await Promise.all(promises!);
      addDoc(collection(firestore, "news"), {
        thumbnail: downloadURL,
        title: title,
        subtitle: subtitle,
        gallery: images,
        description: description,
        deanerName: _selectedDeanery,
        selectedParishName: _selectedParish,
        id: new Date(),
        createdAt: new Date(),
        approved: true,
        postedBy: postedBy,
      })
        .then((res) => {
          showSnackbar("News uploaded successfully");
        })
        .catch((e) => {
          showSnackbar("Something went wrong");
        });

      await Promise.all(promises!);
    } catch (error) {
      console.error("Error uploading images: ", error);
    } finally {
      setUploading(false);
      setSelectedImages([]);
    }
  };

  return (
    <AdminDashboardLayout>
      <Box flex={1} mt={2} mx={2}>
        <Grid container>
          <Grid item md={6} lg={6} xl={6} pb={4}>
            <Card sx={{ width: "100%", borderRadius: 1, m: 3 }} elevation={1}>
              <CardContent>
                <Box display="flex" flexDirection="column" pl={1}>
                  <Box flexDirection="row" display="flex" alignItems="center">
                    <IconButton onClick={() => navigate(-1)}>
                      <ArrowBack></ArrowBack>
                    </IconButton>
                    <Typography variant="h6" fontWeight="600">
                      Add New News
                    </Typography>
                  </Box>
                  <Box>
                    <Box mt={3}>
                      <TextField
                        fullWidth
                        label="News posted by"
                        placeholder="Enter name"
                        value={postedBy}
                        onChange={(event) => {
                          setPostedBy(event?.target.value);
                        }}
                        required
                      />
                    </Box>

                    <Box sx={{ mt: 3 }}>
                      <TextField
                        fullWidth
                        label="Title of the news"
                        placeholder="Enter news title"
                        value={title}
                        onChange={(event) => {
                          setTitle(event?.target.value);
                        }}
                        required
                      />
                    </Box>
                    <Box mt={3}>
                      <TextField
                        fullWidth
                        rows={3}
                        multiline
                        label="Subtitle of the news"
                        placeholder="Enter news subtitle"
                        value={subtitle}
                        onChange={(event) => {
                          setSubTitle(event?.target.value);
                        }}
                        required
                      />
                    </Box>

                    <Box display={"flexx"} mt={0} width={"100%"}>
                      <FormControl fullWidth margin="normal">
                        <FormLabel>Thumbnail</FormLabel>
                        <input
                          type="file"
                          accept="image/*"
                          style={{
                            border: `1px solid rgb(181, 176, 176)`,
                            width: "96%",
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 16,
                            paddingBottom: 16,
                            borderRadius: 5,
                            marginTop: 8,
                          }}
                          onChange={(e) => {
                            if (e.target.files != null) {
                              const filesArray = e.target.files[0];
                              setSelectedThumbnail(filesArray);
                            }
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box mt={3}>
                      <TextField
                        rows={8}
                        multiline
                        fullWidth
                        label="Description of the news"
                        placeholder="Enter news Description"
                        value={description}
                        onChange={(event) => {
                          setDescription(event?.target.value);
                        }}
                        FormHelperTextProps={{ sx: { color: "red" } }}
                        required
                      />
                    </Box>
                    <Box display={"flexx"} width={"100%"}>
                      <FormControl fullWidth margin="normal">
                        <FormLabel>
                          <Typography>Upload images</Typography>
                        </FormLabel>
                        <input
                          type="file"
                          multiple
                          accept="image/*"
                          style={{
                            border: `1px solid rgb(181, 176, 176)`,
                            width: "96%",
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 16,
                            paddingBottom: 16,
                            borderRadius: 5,
                            marginTop: 8,
                          }}
                          onChange={(e) => {
                            if (e.target.files != null) {
                              const filesArray = Array.from(e.target.files);
                              setSelectedImages((prevImages) => [
                                ...prevImages,
                                ...filesArray,
                              ]);
                            }
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"}>
                      {selectedImages.length > 0 &&
                        selectedImages?.map((file, index) => {
                          return (
                            <Box
                              display={"flex"}
                              flexDirection={"row"}
                              position={"relative"}
                            >
                              <img
                                src={URL.createObjectURL(selectedImages[index])}
                                alt="Preview"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "80px",
                                  marginTop: "10px",
                                  marginRight: 8,
                                  borderRadius: 5,
                                }}
                              />
                              <Box
                                display={"flex"}
                                position={"absolute"}
                                left={0}
                                top={8}
                              >
                                <IconButton
                                  size={"small"}
                                  onClick={() => {
                                    let filter = selectedImages?.filter(
                                      (i) => i != file
                                    );
                                    setSelectedImages(filter);
                                  }}
                                  style={{ color: "#ffffff" }}
                                >
                                  <Close />
                                </IconButton>
                              </Box>
                            </Box>
                          );
                        })}
                    </Box>
                    <Box mt={3}>
                      <Autocomplete
                        fullWidth
                        value={
                          deaneryList.find(
                            (option) => _selectedDeanery?.id === option.id
                          ) || null
                        }
                        onChange={handleDeaneryChange}
                        options={deaneryList}
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Deanery Name"
                          />
                        )}
                      />
                    </Box>
                    <Box mt={3}>
                      <Autocomplete
                        fullWidth
                        value={
                          parishList.find(
                            (option: Parish) =>
                              _selectedParish?.id === option.id
                          ) || null
                        }
                        onChange={(
                          e: React.SyntheticEvent,
                          value: Parish | null
                        ) => _setSelectedParish(value)}
                        options={parishList}
                        getOptionLabel={(option: Parish) => option.value || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Parish name"
                          />
                        )}
                        disabled={!parishList.length}
                      />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      mt={4}
                    >
                      <Button
                        onClick={() => navigate("/admin/users")}
                        variant="outlined"
                        sx={{
                          minWidth: 200,
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        onClick={() => {
                          handleUpload();
                        }}
                        sx={{
                          minWidth: 200,
                        }}
                        variant="contained"
                      >
                        Create Post
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </AdminDashboardLayout>
  );
}

export default UploadNews;
