import React from "react";
import BaseLayout from "../../layouts/base/base_layout";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <BaseLayout>
      <Grid container spacing={3} justifyContent="center">
        <Grid item md={4}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            m={4}
          >
            <img
              src={"/images/undraw_floating_hvri.svg"}
              width="100%"
              height="100%"
            />
            <Box
              m={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography variant="h6">Page not found</Typography>
              <Button onClick={() => navigate(-1)}>Go Back</Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </BaseLayout>
  );
}
