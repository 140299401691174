import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Serializer, surveyLocalization } from "survey-core";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { ArrowBack } from "@mui/icons-material";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import "survey-core/survey.i18n";
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDoc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { useFirestore } from "reactfire";
import { useParams } from "react-router-dom";
import { useFeedback } from "../../../../providers/feedback/feedback";
import AdminDashboardLayout from "../../../../layouts/admin_dashboard_layout/admin_dashboard_layout";

interface Props {
  isEditFlow: boolean;
}

function CreateSurvey({ isEditFlow }: Props) {
  const { id } = useParams();
  const { showSnackbar } = useFeedback();
  const firestore = useFirestore();
  const [surveyData, setSurveyData] = useState<DocumentData | null>(null);

  const db = getFirestore();

  const creator = useMemo(
    () =>
      new SurveyCreator({
        showLogicTab: true,
        isAutoSave: true,
        haveCommercialLicense: true,
        showTranslationTab: true,
        showJSONEditorTab: true,
        questionTypes: [
          "boolean",
          "checkbox",
          "comment",
          "dropdown",
          "tagbox",
          "matrix",
          "matrixdropdown",
          "matrixdynamic",
          "multipletext",
          "radiogroup",
          "rating",
          "ranking",
          "text",
          "imagepicker",
          "html",
          "file",
        ],
      }),
    []
  );

  async function submitChoiceFunc(userId?: string) {
    let questionObject = creator.JSON;
    let questions = {
      title: questionObject?.title,
      description: questionObject?.description,
      logoPosition: questionObject?.logoPosition,
      pages: questionObject?.pages,
    };

    let isTranslationExistsData =
      questions?.pages?.find((page: any) => {
        return page.elements?.find((element: any) => {
          return typeof element?.title == "object" && !element?.title?.default;
        });
      }) || [];

    let isTitleExists =
      typeof questions?.title === "object"
        ? !!questions?.title?.default
        : !!questions?.title;
    let isDescriptionExists =
      typeof questions?.description === "object"
        ? !!questions?.description?.default
        : !!questions?.description;

    if (!isTitleExists) {
      showSnackbar("Please enter title for the survey");
    } else if (!isDescriptionExists) {
      showSnackbar("Please enter description for the survey");
    } else if (isTranslationExistsData?.length !== 0) {
      showSnackbar(
        `Please enter default translation for
      ${isTranslationExistsData?.elements?.[0]?.name}`
      );
    } else {
      let countTotalQuestions = 0;
      questions?.pages?.forEach((page: any) => {
        countTotalQuestions += page.elements.length;
      });
      const usersCollection = collection(firestore, "survey");

      if (isEditFlow) {
        try {
          if (userId) {
            const userRef = doc(db, "survey", userId);
            await updateDoc(userRef, {
              createdAt: Date().toString(),
              updatedAt: Date().toString(),
              surveyQuestionaire: questions,
              totalQuestions: countTotalQuestions,
            });
          } else {
            showSnackbar("User ID is missing");
          }
          showSnackbar("Survey updated successfully");
        } catch (error: any) {
          showSnackbar("Error updating user:", error);
        }
      } else {
        addDoc(usersCollection, {
          createdAt: Date().toString(),
          updatedAt: Date().toString(),
          surveyQuestionaire: questions,
          totalQuestions: countTotalQuestions,
        }).then((res) => {
          showSnackbar("Survey Created successfully!");
        });
      }
    }
  }

  const getSpecificRecord = async () => {
    try {
      const docRef = doc(firestore, "survey", id!);
      const docSnap = await getDoc(docRef);
      // setIsLoading(false);
      if (docSnap.exists()) {
        setSurveyData(docSnap.data());
        console.log("Document data:", docSnap.data());
        return docSnap.data();
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching document:", error);

      return null;
    }
  };

  useEffect(() => {
    surveyLocalization.supportedLocales = ["en"];
    getSpecificRecord();
  }, []);

  useEffect(() => {
    if (surveyData) {
      creator.JSON = surveyData?.surveyQuestionaire;
    }
  }, [surveyData]);

  useEffect(() => {
    const banner: any = document.querySelector(".svc-creator__banner");
    if (banner) {
      banner.style.display = "none";
    }
  }, []);

  return (
    <AdminDashboardLayout>
      <Box>
        <SurveyCreatorComponent
          style={{
            ".svc-creator__banner": {
              display: "none !important",
            },
          }}
          creator={creator}
          key={"surveyCreator"}
        />
        <Grid container my={5}>
          <Grid item md={6} lg={6} sm={12} mx={2}>
            <Box
              display="flex"
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  //    navigate(-1)
                }}
                fullWidth
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (isEditFlow) {
                    submitChoiceFunc(id!);
                  } else {
                    submitChoiceFunc();
                  }
                }}
                fullWidth
                sx={{ ml: 3 }}
              >
                {isEditFlow ? "Edit Survey" : "Save changes"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </AdminDashboardLayout>
  );
}

export default CreateSurvey;
