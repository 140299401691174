import { Menu } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import React, { PropsWithChildren } from "react";
import Drawer from "./drawer";
import Breadcrumb from "../../components/breadcrumbs/breadcrubm";
import MobileDrawer from "./mobileDrawer";
import useUser from "../../providers/user_provider/user_provider";

interface AdminDashboardLayoutProps {}

function AdminDashboardLayout({
  children,
}: PropsWithChildren<AdminDashboardLayoutProps>) {
  const { setMobileDrawerOpen, mobileDrawerOpen } = useUser();

  const handleDrawerOpen = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };
  return (
    <Box
      display={"flex"}
      flex={1}
      height={"100%"}
      flexDirection={"column"}
      sx={{ backgroundColor: "#DEEFF5" }}
    >
      <Box display="flex" mx={4} my={3}>
        <Grid container>
          <Hidden mdDown>
            <Grid
              item
              md={2}
              xl={2}
              lg={2}
              bgcolor={"white"}
              sx={{ borderRadius: 2 }}
            >
              <Box display={"flex"} flexDirection={"column"} height={"95vh"}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  mx={2}
                  mt={2}
                  alignItems={"center"}
                  mb={3}
                >
                  <Avatar src="/images/icons/icym_icon.jpg" />
                  <Typography fontWeight={600} ml={2}>
                    Youth Commission
                  </Typography>
                </Box>
                <Drawer />
              </Box>
            </Grid>
          </Hidden>

          <Grid item md={10} xl={10} lg={10} sm={12} xs={12}>
            <Box mx={2}>
              <Card
                sx={{
                  borderRadius: 2,
                }}
              >
                <Box height={70} bgcolor={"white"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    height={"100%"}
                    ml={2}
                  >
                    <IconButton
                      color="primary"
                      aria-label="open drawer"
                      edge="end"
                      onClick={handleDrawerOpen}
                      sx={{
                        ...(mobileDrawerOpen && { display: "none" }),
                        p: 0,
                        m: 0,
                      }}
                    >
                      <Menu />
                    </IconButton>
                  </Box>
                </Box>
              </Card>
            </Box>
            <Box display={"flex"} m={2}>
              <Card
                sx={{
                  display: "flex",
                  height: "95%",
                  borderRadius: 2,
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <Box ml={3}>
                  <Breadcrumb />
                </Box>
                {children}
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default AdminDashboardLayout;
