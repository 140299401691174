import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { collection, doc, query } from "firebase/firestore";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestore, useFirestoreDocData } from "reactfire";
import AdminDashboardLayout from "../../../../layouts/admin_dashboard_layout/admin_dashboard_layout";

interface Props {}

function Details(props: Props) {
  const { id } = useParams();
  const firestore = useFirestore();
  const {} = props;
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);

  const surveyDocRef = doc(firestore, "survey", "cTbJQ4Ye3LCBSbm0OtWt");
  const surveyResponsesCollection = collection(surveyDocRef, "surveyResponses");

  const respondentDocRef = doc(surveyResponsesCollection, id!);
  const { data: respondentDetails } = useFirestoreDocData(respondentDocRef);

  function listItem({
    title,
    value,
  }: {
    title: string;
    value: any;
  }): JSX.Element {
    return (
      <Box>
        <Typography color="primary.main">{title}</Typography>
        <Typography>{value || "-"}</Typography>
      </Box>
    );
  }

  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };

  return (
    <AdminDashboardLayout>
      <Grid container spacing={3} mx={2} my={2}>
        <Grid item md={8} lg={7} sm={12} xs={12}>
          <Card sx={{ width: "100%", borderRadius: 2, my: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="row">
                <Box display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography
                    variant={isMobile ? "h6" : "h6"}
                    fontWeight="600"
                    paddingLeft={1}
                  >
                    Respodent Details
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                ></Box>
              </Box>
              <Box mt={2} mb={2}>
                <Divider />
              </Box>
              <Box m={2}>
                <Box overflow="auto" flex={1}>
                  <Grid container spacing={3}>
                    <Grid item md={12} lg={12} sm={12} xs={12}>
                      <Tabs
                        value={currentTab}
                        aria-label="basic tabs example"
                        onChange={handleChange}
                        sx={{
                          "MuiTabs-root css-112qdbf-MuiTabs-root": {
                            borderColor: "#F5E6F5",
                          },
                          mt: 1,
                        }}
                      >
                        <Tab
                          label="User identification"
                          sx={{
                            textTransform: "capitalize",
                            color: "#1B001B",
                          }}
                        />
                        <Tab
                          label="Personal Details"
                          sx={{ textTransform: "capitalize", color: "#1B001B" }}
                        />
                        <Tab
                          label="Education and Work Details"
                          sx={{ textTransform: "capitalize", color: "#1B001B" }}
                        />
                        <Tab
                          label="Consent"
                          sx={{ textTransform: "capitalize", color: "#1B001B" }}
                        />
                      </Tabs>
                    </Grid>
                    {currentTab === 0 && (
                      <>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Survey Code",
                            value: respondentDetails?.answers?.surveyCode,
                          })}
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Deanery Name",
                            value: respondentDetails?.answers?.deaneryName,
                          })}
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Parish Name",
                            value: respondentDetails?.answers?.parishName,
                          })}
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Pin code",
                            value: respondentDetails?.answers?.pincode,
                          })}
                        </Grid>
                      </>
                    )}

                    {currentTab === 1 && (
                      <>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Full name",
                            value: respondentDetails?.answers?.fullName,
                          })}
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Age",
                            value: respondentDetails?.answers?.age,
                          })}
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Gender",
                            value: respondentDetails?.answers?.gender,
                          })}
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Contact Details",
                            value: respondentDetails?.answers?.phoneNumber,
                          })}
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Father nama",
                            value: respondentDetails?.answers?.fatherName,
                          })}
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Mother name",
                            value: respondentDetails?.answers?.motherName,
                          })}
                        </Grid>
                      </>
                    )}

                    {currentTab === 2 && (
                      <>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Highest Qualification",
                            value:
                              respondentDetails?.answers?.highestQualification,
                          })}
                        </Grid>

                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Field of study",
                            value: respondentDetails?.answers?.fieldOfStudy,
                          })}
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Current Occupation",
                            value: respondentDetails?.answers?.occupation,
                          })}
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Job Title",
                            value: respondentDetails?.answers?.jobTitle,
                          })}
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Industry",
                            value: respondentDetails?.answers?.industry,
                          })}
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Company name",
                            value: respondentDetails?.answers?.compnayName,
                          })}
                        </Grid>
                      </>
                    )}
                    {currentTab === 3 && (
                      <>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          {listItem({
                            title: "Consent",
                            value: respondentDetails?.answers?.consent
                              ? "Yes"
                              : "No",
                          })}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </AdminDashboardLayout>
  );
}

export default Details;
